<template>
  <section class="section" id="service">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="title-box">
            <h2
              class="fadeIn animated wow"
              data-wow-delay=".1s"
            >{{$t("You're here because you want the best")}}</h2>
            <h3>{{$t("And we know it")}}</h3>
            <div class="border"></div>
          </div>
        </div>
      </div>
      <div class="row align-items-start pt-5">
        <div class="media-list col-md-4">
          <li class="media">
            <a class="pull-left" href="#">
              <img class="media-object mr-2" src="../../assets/images/icons/doc-sync.svg" alt="img" />
            </a>
            <div class="media-body">
              <h4>
                <b>{{$t("CI/CD for your application")}}</b>
              </h4>
              <p>{{$t("We create all environment for create containers automatically when you push a commit of your code.")}}</p>
            </div>
          </li>
        </div>

        <div class="media-list col-md-4">
          <li class="media">
            <a class="pull-left" href="#">
              <img
                class="media-object mr-2"
                src="../../assets/images/icons/laptop-globe.svg"
                alt="img"
              />
            </a>
            <div class="media-body">
              <h4>
                <b>{{$t("Kurlabs CTL - Powerful and unified interface")}}</b>
              </h4>
              <p>{{$t("We provide you an interface for monitor, access, and manage your application in the servers. With our Kurlabs CTL system it's possible!")}}</p>
            </div>
          </li>
        </div>

        <div class="media-list col-md-4">
          <li class="media">
            <a class="pull-left" href="#">
              <img
                class="media-object alerty-img mr-2"
                src="../../assets/images/icons/png/alerty.png"
                alt="img"
              />
            </a>
            <div class="media-body">
              <h4>
                <b>{{$t("Alerting")}}</b>
              </h4>
              <p>{{$t("You could have a new email when the applications is down.")}}</p>
            </div>
          </li>
        </div>

        <div class="col-12 text-center mt-5">
          <h2 style="font-size: 2.5rem">{{$t("One platform")}}</h2>
          <h3>{{$t("To rule them all")}}</h3>
          <!-- <a
            href
            class="btn btn-primary btn-shadow btn-rounded w-lg animated fadeInDown wow mt-3"
            data-wow-delay=".4s"
          >{{$t("Get Started")}}</a>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services"
};
</script>
<style scoped>
.alerty-img {
  width: 52px !important;
  height: 52px !important;
  margin-top: -10px !important;
  margin-right: 2em !important;
}
</style>



